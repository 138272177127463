import React from 'react';
import styled, { css } from 'styled-components';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { track } from 'context/AnalyticsProvider';

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	text-align: ${p => (p.centered && 'center') || 'left'};
`;

const Wrapper = styled.button`
	transition: all 250ms ease;
	display: flex;
	align-items: flex-start;
	padding: 25px 35px;
	text-align: left;
	background: ${p => p.theme.colors.blue200};
	color: ${p => p.theme.colors.grey900};
	border-radius: ${p => p.theme.utils.borderRadius};
	transition: all 350ms ease;
	width: 100%;
	text-decoration: none;
	cursor: pointer;
	transition: all 350ms ease;
	position: relative;
	> div {
		width: 100%;
		pointer-events: none;
	}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 15px;
		`)}
	&:hover {
		background: ${p => p.theme.colors.blue200};
		box-shadow: ${p =>
			`inset -2px 0 0 ${p.theme.colors.blue400}, inset 0 -2px 0 ${p.theme.colors.blue400}, inset 2px 0 0 ${p.theme.colors.blue400}, inset 0 2px 0 ${p.theme.colors.blue400}`};
	}
	&:disabled {
		background: ${p => p.theme.colors.grey200};
		box-shadow: none;
		cursor: not-allowed;
		color: ${p => p.theme.colors.grey800};
		div {
			color: ${p => p.theme.colors.grey500};
		}
		svg {
			color: ${p => p.theme.colors.grey500};
		}
	}
`;

const Icon = styled.div`
	color: ${p => p.theme.colors.blue700};
	svg {
		width: 28px;
		height: auto;
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				width: 20px;
			`)}
	}
`;

const IconAndContentWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	height: 100%;
`;

const Arrow = styled.div`
	transition: all 350ms ease;
	align-self: center;
	margin-left: auto;
	svg {
		width: 14px;
		height: 22px;
		display: block;
		transition: all 350ms ease;
		color: ${p => p.theme.colors.blue700};
		${p =>
			p.theme.media.smallOnly(css`
				width: 12px;
				height: 18px;
			`)}
	}
`;

const CardContentWrapper = styled.div`
	width: 100%;
	max-width: 300px;
	text-align: start;
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
`;

const CardTitle = styled.div`
	color: ${p => p.theme.colors.grey900};
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.1875rem;
	display: flex;
	align-items: center;
`;

const Description = styled.span`
	color: ${p => p.theme.colors.grey900};
	font-weight: 400;
	font-size: 0.75rem;
	flex: none;
	order: 2;
	align-self: stretch;
	flex-grow: 0;
`;

export default function Card({
	title,
	description,
	icon,
	color,
	onClick,
	...rest
}) {
	return (
		<Wrapper
			onClick={() => {
				track('Button Clicked', {
					label: title,
					category: window.location.pathname,
				});
				onClick();
			}}
			{...rest}>
			<IconAndContentWrapper>
				{icon && <Icon color={color}>{icon}</Icon>}
				<CardContentWrapper>
					{title && <CardTitle>{title}</CardTitle>}
					{description && <Description>{description}</Description>}
				</CardContentWrapper>
				<Arrow color={color}>
					<FontAwesomeIcon
						icon={faAngleRight}
						size="lg"
						width="28"
						height="25"
					/>
				</Arrow>
			</IconAndContentWrapper>
		</Wrapper>
	);
}
