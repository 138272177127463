import React from 'react';
import styled, { css } from 'styled-components';

import { authMethods, useAuth } from 'context/AuthProvider';
import BankidLogo from 'images/strombestilling/bankid-logo.inline.svg';
import VippsEmoji from 'images/vipps/vipps-emoji.inline.svg';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Shortcuts from 'parts/content-grid-elements/Shortcuts';

export const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	.shortcut__title {
		margin-bottom: 0 !important;
	}
`;

const Wrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	max-width: ${p => p.theme.widths.medium};
	width: 100%;
	margin: ${p => (p.$centered === 'true' && '0 auto') || '0'};
	${p =>
		p.theme.media.mediumDown(css`
			margin: ${p => (p.$centered === 'true' && '0 auto') || '0'};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
		`)}
	> button {
		display: block !important;
		margin: 40px auto 0 !important;
		${p =>
			p.theme.media.smallOnly(css`
				margin: 20px auto 0 !important;
			`)}
	}
`;

const VippsEmojiIcon = styled(VippsEmoji)`
	width: 24px;
	height: 26px;
`;

const BankidIcon = styled(BankidLogo)`
	width: 24px;
	height: 26px;
`;

/**
 * Represents a component for displaying authentication buttons.
 * @param {Object} props - The properties for the AuthButtons component.
 * @param {string} [props.title='Først må vi vite hvem du er'] - The title for the component.
 * @param {string} [props.text='For å vite sikkert at du er den du sier, trenger vi at du identifiserer deg.'] - The text for the component.
 * @param {string} [props.headinglevel='h3'] - The heading level for the component.
 * @param {string} [props.headinglevelclass=''] - The heading level class for the component.
 * @param {boolean} [props.vipps=true] - Flag to determine whether to show the Vipps button.
 * @param {boolean} [props.bankID=true] - Flag to determine whether to show the BankID button.
 * @param {string} [props.redirect] - The redirect URL for the authentication.
 * @param {string} [props.centered='false'] - Flag to determine whether to center the component.
 * @returns {ReactNode} - A React element representing the AuthButtons component.
 */
export default function AuthButtons({
	title = 'Først må vi vite hvem du er',
	text = 'For å vite sikkert at du er den du sier, trenger vi at du identifiserer deg.',
	headinglevel = 'h3',
	headinglevelclass = '',
	vipps = true,
	bankID = true,
	redirect,
	centered = 'false',
}) {
	const { login, isAuthenticated } = useAuth();

	if ((!vipps && !bankID) || isAuthenticated) return null;

	const cardElements = [];
	if (vipps) {
		cardElements.push({
			title: 'Vipps',
			text: 'Vi forhåndsutfyller med informasjonen din fra Vipps',
			icon: <VippsEmojiIcon role="img" />,
			fontAwesome: false,
			iconColor: '#ff5b24',
			dataCy: 'vipps-auth-button',
			onClick: () => {
				login({ redirect, method: authMethods.vipps });
			},
		});
	}

	if (bankID) {
		cardElements.push({
			title: 'bankID',
			text: 'Identifiser deg med bankID',
			icon: <BankidIcon role="img" />,
			fontAwesome: false,
			iconColor: '#39134C',
			dataCy: 'bankid-auth-button',
			onClick: () => {
				login({ redirect, method: authMethods.bankId });
			},
		});
	}

	return (
		<Wrapper $centered={centered}>
			<TitleAndText
				title={title}
				text={text}
				headinglevel={headinglevel}
				headinglevelclass={headinglevelclass}
				centered={centered}
				nested={true}
			/>

			<CardWrapper $centered={centered}>
				<Shortcuts
					elements={cardElements}
					evenCount={cardElements?.length % 2 === 0}
					perrow={1}
					iconPosition="center"
				/>
			</CardWrapper>
		</Wrapper>
	);
}
