import React from 'react';
import styled from 'styled-components';

import { authMethods } from 'context/AuthProvider';
import { usePopup } from 'context/PopupProvider';
import Popup from 'components/popup/Popup';
import BlankButton from 'components/forms/BlankButton';
import { AnchorLink } from 'components/Link';

const Wrap = styled.div`
	> button:last-of-type {
		font-size: 14px;
		line-height: 22px;
		margin: ${p =>
			p.$centered === 'true'
				? '30px auto 0 !important'
				: '30px 0 0 !important'};
	}
`;

export default function AuthInformation({
	id = 'order-auth-desc',
	vipps = true,
	bankID = true,
	type = 'order',
	centered = 'true',
	size = 'medium',
}) {
	const { activePopup, setActivePopup } = usePopup();

	const method =
		vipps && bankID
			? `${authMethods.vipps} eller ${authMethods.bankId}`
			: vipps
			? authMethods.vipps
			: authMethods.bankId;

	return (
		<Wrap $centered={centered}>
			<BlankButton
				title={`Hvorfor må jeg bruke ${method}?`}
				onClick={() => setActivePopup(id)}
				aria-haspopup={true}
				aria-controls={id}
				aria-expanded={activePopup === id}
				tracking={false}>
				Hvorfor må jeg bruke {method}?
			</BlankButton>
			<Popup
				id={id}
				title={`Hvorfor må jeg bruke ${method}?`}
				size={size}>
				<>
					<div>
						<h3>{`Hvorfor må jeg bruke ${method}?`}</h3>
						{(type === 'order' && (
							<>
								For at ikke noen skal bestille på vegne av
								andre, eller bli utsatt for svindel, ønsker vi
								at kundene våre identifiserer seg. Ved å bruke{' '}
								{method} kan vi også forhåndsutfylle
								informasjonen din slik at bestillingen går
								raskere.
							</>
						)) || (
							<>
								For at ikke noen skal utgi seg for å være noen,
								eller bli utsatt for svindel, ønsker vi at
								brukerne våre identifiserer seg. Ved å bruke{' '}
								{method} kan vi også forhåndsutfylle
								informasjonen din slik at identifiseringen går
								raskere.
							</>
						)}
					</div>
					{bankID && (
						<div>
							<h3 style={{ marginTop: '20px' }}>
								Kontakt banken din for å få Bank-ID
							</h3>
							For å få BankID må du møte opp i banken din. Der
							viser du passet ditt, og du får god hjelp til å
							komme i gang med BankID - din digitale identitet.
							Det er også banken som hjelper deg hvis du skulle ha
							spørsmål eller får problemer med BankID.
						</div>
					)}
					<div>
						<h3 style={{ marginTop: '20px' }}>Andre spørsmål?</h3>
						Har du spørsmål til{' '}
						{(type === 'order' && 'bestillingen') ||
							'identifiseringen'}{' '}
						ta kontakt med vår kundeservice på{' '}
						<AnchorLink href="tel:+4774150200">
							+47 74 15 02 00
						</AnchorLink>{' '}
						eller via{' '}
						<AnchorLink href="/kundeservice/chat/" target="_blank">
							vår chat
						</AnchorLink>
					</div>
				</>
			</Popup>
		</Wrap>
	);
}
